<template>
  <div class="ma-0 pa-0" v-if="pageRender">
    <admin-header
      v-if="isEdit"
      :title="'Edit Profile'"
      :links="editLinks"
    ></admin-header>
    <v-container v-else class="ma-0 mb-4 pa-0">
      <div class="mt-12"></div>
      <!-- <div class="mt-12 pt-6"></div> -->
    </v-container>
    <card-container>
      <div class="mt-n12 pt-n12 mr-6" slot="left">
        <div class="header-text">
          <h1 class="textOrange--text display-1 pb-8">
            Time to {{ !isEdit ? "Build" : "Modify" }} your <br />Introduction
            BeeZCard
          </h1>
        </div>
        <div class="ma-0 pa-0">
          <div>
            <h1 class="textDark--text headline pb-0">Steps</h1>
          </div>
          <v-stepper
            v-model="stepper"
            vertical
            class="stepper-contianer  elevation-0"
          >
            <div v-for="(step, index) in steps" :key="index">
              <v-stepper-step
                class="px-0 py-0"
                :step="index + 1"
                :complete="index === completedSteps[index] || isComplete"
                @change="setStepper($event)"
              >
                <label
                  v-if="!isComplete"
                  class="font-weight-thin title"
                  :class="index + 1 <= stepper ? 'primaryLight--text' : ''"
                  >{{ step.label }}</label
                >
                <p
                  v-else
                  class="font-weight-thin title primaryLight--text pa-0 ma-0"
                >
                  {{ step.label }}
                </p>
              </v-stepper-step>
              <v-divider
                v-if="index < 4"
                class="mx-3 primary"
                style="height:25px"
                vertical
              ></v-divider>
            </div>
          </v-stepper>
        </div>
      </div>
      <div slot="right">
        <div>
          <introduction-preview
            :jobTitle="card.jobTitle"
            :aboutTitle="card.aboutTitle"
            :section0="card.sections[0].title"
            :section1="card.sections[1].title"
            :userCardData="userCardData"
            :userImage="image"
            v-on:dialogData="submittedData($event)"
          />
        </div>
        <div class="my-4 mx-0 pa-0">
          <bz-button
            @click="displayPreview"
            width="320"
            title="Preview Card"
            color="primaryLight"
            class="text-capitalize"
            :disabled="!disableBtn"
          ></bz-button>
        </div>
      </div>
    </card-container>
    <bz-notification v-model="notification"></bz-notification>
    <v-dialog width="320" v-model="previewDialog">
      <intro-card-for-preview
        :userCardData="userCardData"
        :card="card"
        :imageUrl="imageUrl"
      />
    </v-dialog>
    <congratulations
      :display="display"
      @preview="displayPreview"
      continueTo="orientation-landing-page"
      type="introduction"
    ></congratulations>
 <bz-footer
      text="Finish Customizing your Introduction BeeZCard"
      buttonTitle="Continue"
      @click="onSubmitCard"
      icon="mdi-arrow-expand-right"
      :position="position"
      :disabled="!disableBtn"
       :loading="loading"
    ></bz-footer>
  </div>
  <div v-else class="d-flex justify-center align-center" style="height: 50vh;">
    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import IntroductionPreview from "./IntroductionPreview";
import CardContainer from "../../../components/CardContainer";
import { eventBus } from "@/main";
import BzNotification from "@/shared/components/BzNotification";
import Congratulations from "@/beez-modules/beez-user/components/Congratulations";
import IntroCardForPreview from "./IntroCardForPreview";
import AdminHeader from "@/beez-admin/components/AdminHeader";

export default {
  data() {
    return {
      position: "right",
      card: {
        id: null,
        jobTitle: "",
        aboutTitle: "",
        sections: ["", ""]
      },
      isEdit: false,
      congraSeen: false,
      editLinks: [
        {
          text: "Your Profile",
          disabled: false,
          to: {
            name: "user-profile"
          }
        },
        {
          text: "Edit",
          disabled: true
        }
      ],
      userCardData: {
        profilePictureId: "",
        fullName: "",
        about: "",
        position: "",
        cardCustomizationId: "",
        cardTags: [
          {
            cardCustomizationSectionId: "",
            tags: ["", "", ""]
          },
          {
            cardCustomizationSectionId: "",
            tags: ["", "", ""]
          }
        ],
        accountModuleId: ""
      },
      steps: [],
      pageRender: false,
      accountId: null,
      stepper: 1,
      imageUrl: null,
      image: null,
      loading: false,
      display: false,
      previewDialog: false,
      isComplete: false
    };
  },
  methods: {
    ...mapActions("introduction", {
      getIntroductionCard: "getIntroductionCard",
      syncStep: "syncStep",
      syncCompleted: "syncCompleted"
    }),
    ...mapActions("user", {
      createUserCard: "createUserCard",
      getUserCard: "getUserCard"
    }),
    ...mapActions("admin", {
      fetchFilesUploaded: "fetchFilesUploaded"
    }),
    submittedData(data) {
      const { dialogData, step } = data;
      switch (step) {
        case 0:
          this.userCardData.profilePictureId = dialogData;
          break;
        case 1:
          this.userCardData.position = dialogData[0].model;
          break;
        case 2:
          this.userCardData.about = dialogData[0].model;
          break;
        case 3:
          let tagsA = [];
          dialogData.forEach(function(item) {
            tagsA.push(item.model);
          });
          this.userCardData.cardTags[0].tags = tagsA;
          break;
        case 4:
          let tagsB = [];
          dialogData.forEach(function(item) {
            tagsB.push(item.model);
          });
          this.userCardData.cardTags[1].tags = tagsB;
          break;
      }
    },
    displayPreview() {
      this.display = false;
      this.previewDialog = true;
    },
    setStepper(currentStep) {
      // console.log("Change");
      this.syncStep({ Stepper: currentStep });
    },
    async onSubmitCard() {
      this.loading = true;
      try {
        await this.createUserCard({ cardData: this.userCardData });
        if (this.isEdit) {
          this.$router.push({ name: "user-profile" });
        } else {
          if (this.congraSeen) {
            this.$router.push({ name: "orientation" });
          } else {
            this.display = true;
            this.congraSeen = true;
          }
        }
      } catch (error) {
        // this.notify("error", "An unexpected error has occured");
      } finally {
        this.loading = false;
      }
    },
    setCard(card) {
      this.card.id = card[0]._id;
      this.card.jobTitle = card[0].jobTitle;
      this.card.aboutTitle = card[0].aboutTitle;
      let sections = [];
      let sectionObj = {};
      for (var index in card[0].sections) {
        sectionObj["id"] = card[0].sections[index]._id;
        sectionObj["title"] = card[0].sections[index].title;
        sections.push(sectionObj);
        sectionObj = {};
      }
      this.card.sections = sections;
      this.userCardData.cardTags[0].cardCustomizationSectionId =
        card[0].cardCustomizationSectionIds[0];
      this.userCardData.cardTags[1].cardCustomizationSectionId =
        card[0].cardCustomizationSectionIds[1];
      this.userCardData.accountModuleId = card[0].accountModuleId;
      this.userCardData.cardCustomizationId = card[0]._id;
      this.userCardData.fullName =
        this.currentUser.firstName + " " + this.currentUser.lastName;
    },
    fillCard(cardData) {
      this.userCardData.position = cardData[cardData.length - 1].position;
      this.userCardData.about = cardData[cardData.length - 1].about;
      let tagsA = [];
      let tagsB = [];
      cardData[cardData.length - 1].cardTags.forEach(function(item, index) {
        if (index < 3) {
          tagsA.push(item.tag);
        } else {
          tagsB.push(item.tag);
        }
      });
      this.userCardData.cardTags[0].tags = tagsA;
      this.userCardData.cardTags[1].tags = tagsB;
      this.userCardData.profilePictureId =
        cardData[cardData.length - 1].profilePictureId;
    },
    completedSteppers() {
      // check if already there currentStepper
      let check = null;
      check = this.completedSteps.find(step => step === this.currentStepper);
      if (check === null || !check) {
        let data = this.completedSteps;
        data.splice(this.currentStepper, 0, this.currentStepper);
        this.stepper += 1;
        this.syncCompleted({ completed: data });
      }
    },
    setSteps(card) {
      return [
        {
          label: "Upload BeeZCard Photo",
          completed: false
        },
        {
          label: "Add Job Title / Position",
          completed: false
        },
        {
          label: "Write Something About You",
          completed: false
        },
        {
          label: `Add 3 ${card[0].sections[0].title} BeeZTags`,
          completed: false
        },
        {
          label: `Add 3 ${card[0].sections[1].title} BeeZTags`,
          completed: false
        }
      ];
    }
  },
  async created() {
    this.accountId = this.currentUser.roles[0].accountId;
    const card = await this.getIntroductionCard({ accountId: this.accountId });
    const cardData = await this.getUserCard();

    if (card && card.length > 0) {
      this.setCard(card);
      this.steps = this.setSteps(card);
    }
    if (cardData && cardData.length > 0) {
      this.fillCard(cardData);
      let data = [0, 1, 2, 3, 4];
      this.syncCompleted({ completed: data });
      this.stepper = 6;
      this.isComplete = true;
    }
    if (this.userCardData.profilePictureId) {
      try {
        const userImage = await this.fetchFilesUploaded({
          logoId: this.userCardData.profilePictureId
        });
        if (userImage) {
          this.image = userImage;
          this.imageUrl = userImage.url;
        }
      } catch (error) {}
    }
    this.pageRender = true;
    eventBus.$on("imageUploadCompletedEvent", () => {
      this.completedSteppers();
    });
    eventBus.$on("imageChangeEvent", imageFileUrl => {
      this.imageUrl = imageFileUrl;
    });

    eventBus.$on("positionCompletedEvent", () => {
      this.completedSteppers();
    });
    eventBus.$on("bioCompletedEvent", () => {
      this.completedSteppers();
    });
    eventBus.$on("expertiseCompletedEvent", () => {
      this.completedSteppers();
    });
    eventBus.$on("projectsCompletedEvent", () => {
      this.completedSteppers();
    });
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("introduction", ["currentStepper", "completedSteps"]),
    disableBtn() {
      return this.completedSteps.length === 5 || this.isComplete;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.edit) {
          this.isEdit = true;
          // value.meta.breadcrumbs.skip = false;
        } else {
          // value.meta.breadcrumbs.skip = true;
        }
        // console.log(value.meta.breadcrumbs.skip);
      }
    }
  },
  components: {
    CardContainer,
    IntroductionPreview,
    BzNotification,
    Congratulations,
    IntroCardForPreview,
    AdminHeader
  }
};
</script>
<style scoped lang="scss">
.stepper-contianer {
  background: transparent !important;
  border: none;
  box-shadow: none !important;
}
</style>
