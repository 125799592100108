<template>
  <v-dialog v-model="showDialog" max-width="350" @click:outside="closeDialog">
    <v-card>
      <v-card-title
        class="mb-2 full-width subtitle-1 primary font-weight-regular textLight--text justify-center"
      >{{dialogContent.title}}</v-card-title>
      <v-card-actions class="pa-6">
        <v-form
          @submit.prevent="validate"
          ref="form"
          lazy-validation
          class="full-width"
        >
          <div
            v-for="(data, index) in dialogContent.data"
            :key="index"
            class="full-width pa-0 ma-0"
          >
            <v-text-field
              :rules="requiredRules"
              class="pa-0 ma-0"
              v-model="data.model"
              :label="data.placeholder"
            ></v-text-field>
          </div>
          <bz-button
            @click="validate"
            color="textOrange"
            class="mt-3 full-width textLight--text"
            title="Done"
          ></bz-button>
        </v-form>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BzButton from "@/shared/components/BzSubmitButton";

export default {
  name: "BzDialog",
  components: {
    BzButton
  },
  data() {
    return {
      requiredRules: [v => !!v || "Field is required"],
      valid: true
    };
  },
  props: {
    dialogContent: {
      type: Object,
      required: true
    },
    showDialog: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmitDialog");
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.onSubmit();
      }
    },
    closeDialog() {
      this.$emit("onCloseDialog");
    }
  },
  watch: {
    showDialog() {
      // this.$refs.form.reset();
      // console.log(this.showDialog);
    }
  }
};
</script>