<template>
  <v-container class="ma-0 pa-0">
    <BzNotification v-model="notification"></BzNotification>
    <v-card flat align="left" class="intro-card ma-0 pa-0 d-flex flex-column" max-width="320">
      <v-row
        cols="12"
        lg="12"
        sm="12"
        class="textDark ma-0 pa-4 d-flex flex-row bg-image-container"
      >
        <v-col class="ma-0 pa-0 d-flex flex-column text-light" cols="5">
          <v-card
            :loading="uploadLoading"
            flat
            v-if="imageUrl === null"
            class="camera-icon-container ma-0 pa-0"
          >
            <tooltip-component :step="0" :isImage="true" :tooltipMessages="tooltipMessages"></tooltip-component>
          </v-card>

          <div v-else class="image-part ma-0 pa-0">
            <v-img class="ma-0 pa-0" :src="imageUrl" max-height="300" max-width="240">
              <v-row justify="end">
                <v-icon size="30" class="pr-4" color="textLight" @click="reselectPhoto">mdi-camera</v-icon>
              </v-row>
            </v-img>
          </div>
        </v-col>

        <v-col lg="7" cols="12" class="d-flex align-center justify-center">
          <v-row class="ma-0 pa-0 textLight--text">
            <v-col
              lg="12"
              cols="12"
              class="textLight--text font-weight-regular text-uppercase ma-0 pa-0 ml-2"
            >{{ currentUser.firstName }} {{ currentUser.lastName }}</v-col>
            <v-col
              cols="12"
              class="primaryLight--text font-weight-light text-capitalize ma-0 pa-0 ml-2 d-flex flex-row justify-space-between"
            >
              <p
                class="ma-0 pa-0"
              >{{ userCardData.position != '' ? userCardData.position : jobTitle}}</p>

              <v-btn slot="tooltip" @click="openDialog(1)" icon small text class="flat pa-0 ma-0">
                <tooltip-component
                  :step="1"
                  :tooltipMessages="tooltipMessages"
                  :isButton="true"
                  :jobTip="jobTooltip"
                  :side="true"
                >
                  <div slot="tooltip"></div>
                </tooltip-component>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <input type="file" id="avatar" style="display:none;" accept="image/*" ref="onfile" />

        <v-row>
          <avatar-cropper
            v-show="showCropper"
            @changed="handleChange"
            :trigger="avatarTrigger"
            :labels="avatarCropper.labels"
            :cropper-options="avatarCropper.options"
            :output-mime="avatarCropper.mime"
            :upload-handler="uploadAvatar"
          />
        </v-row>
      </v-row>
      <div class="d-flex flex-row justify-space-between mx-5 mt-4 mb-0 pa-0">
        <v-text-field
          v-if="userCardData.about == ''"
          class="ma-0 pa-0"
          :value="aboutTitle"
          readonly
          color="textDark"
          @click="openDialog(2)"
        ></v-text-field>

        <v-text-field
          v-else
          v-model="userCardData.about"
          class="ma-0 pa-0"
          readonly
          color="textDark"
          @click="openDialog(2)"
        ></v-text-field>
        <v-btn @click="openDialog(2)" icon small text class="flat pa-0 my-0 mx-0">
          <tooltip-component
            :step="2"
            :tooltipMessages="tooltipMessages"
            :isButton="true"
            :bioTip="bioTooltip"
            :side="true"
          >
            <div slot="tooltip"></div>
          </tooltip-component>
        </v-btn>
      </div>
      <v-row class="pa-0 d-flex flex-row ma-4">
        <v-col
          class="d-flex flex-column justify-center align-center ma-0 py-0 px-1"
          lg="6"
          md="6"
          sm="6"
        >
          <div
            style="width: 120px;"
            class="d-flex align-self-start justify-space-between pa-0 ma-0"
          >
            <v-col class="ma-0 pa-0" cols="10">{{ section0 == '' ? 'Expertise' : section0 }}</v-col>
            <v-col class="ma-0 pa-0" cols="2">
              <v-btn @click="openDialog(3)" icon small text class="flat pa-0 my-0 mx-3">
                <tooltip-component
                  :step="3"
                  :isButton="true"
                  :expertTip="expertTooltip"
                  :side="false"
                  :tooltipMessages="tooltipMessages"
                >
                  <div slot="tooltip"></div>
                </tooltip-component>
              </v-btn>
            </v-col>
          </div>
          <v-col cols="12" md="12" lg="12" class="ma-0 pa-0 d-flex align-self-start justify-start">
            <bz-tags
              class="ma-0 pa-0"
              darkColor="primaryLight"
              lightColor="lightBlue"
              textColor="primaryLight--text"
              :listOfTags="userCardData.cardTags[0].tags"
              :small="true"
            />
          </v-col>
        </v-col>

        <v-col
          class="d-flex flex-column justify-center align-center ma-0 py-0 px-1"
          lg="6"
          md="6"
          sm="6"
        >
          <div
            style="width: 120px;"
            class="d-flex align-self-start justify-space-between pa-0 ma-0"
          >
            <v-col class="ma-0 pa-0" cols="10">{{ section1 == '' ? 'Projects' : section1}}</v-col>
            <v-col class="ma-0 pa-0" cols="2">
              <v-btn @click="openDialog(4)" icon small text class="flat pa-0 my-0 mx-3">
                <tooltip-component
                  :step="4"
                  :isButton="true"
                  :projectsTip="projectsTooltip"
                  :tooltipMessages="tooltipMessages"
                  :side="true"
                >
                  <div slot="tooltip"></div>
                </tooltip-component>
              </v-btn>
            </v-col>
          </div>
          <v-col cols="12" md="12" lg="12" class="ma-0 pa-0 d-flex align-self-start justify-start">
            <bz-tags
              class="ma-0 pa-0"
              darkColor="textOrange"
              lightColor="lightOrange"
              textColor="textOrange--text"
              :listOfTags="userCardData.cardTags[1].tags"
              :small="true"
            />
          </v-col>
        </v-col>
      </v-row>
    </v-card>
    <bz-dialog
      :showDialog="showDialog"
      :dialogContent="dialogFor"
      @onSubmitDialog="dialogSubmitted()"
      @onCloseDialog="showDialog = false"
    ></bz-dialog>
  </v-container>
</template>

<script>
import BzTags from "@/shared/components/BzTags";
import BzDialog from "../../../components/BzDialog";
import BzNotification from "@/shared/components/BzNotification";
import TooltipComponent from "./TooltipComponent";
import AvatarCropper from "vue-avatar-cropper";
import { eventBus } from "@/main";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "IntroductionPreview",
  data() {
    return {
      user: {
        firstName: "Abebech",
        lastName: "Debebe",
        position: "Senior Accountant",
        about: "",
        expertise: ["", "", ""],
        projects: ["", "", ""]
      },
      tooltipMessages: [
        { text: "Upload Photo", tip: "1 of 5" },
        { text: "Add Job Title / Position", tip: "2 of 5" },
        { text: "Write Something About you", tip: "3 of 5" },
        { text: `Add 3 BeeZTags`, tip: "4 of 5" },
        { text: `Add 3 BeeZTags`, tip: "5 of 5" }
      ],
      image: null,
      imageUrl: null,
      avatarBlob: null,
      uploadLoading: false,
      showCropper: false,
      correctFormat: false,
      selectedAvatarImage: null,
      showDialog: false,
      dialogStep: 0,
      jobTooltip: false,
      bioTooltip: false,
      expertTooltip: false,
      projectsTooltip: false,
      dialogFor: {
        title: "",
        data: [
          {
            model: null,
            placeholder: ""
          }
        ]
      },
      dialogForJobTitle: {
        title: "Add a Job Title / Position",
        data: [
          {
            model: null,
            placeholder: "Job Title / Position"
          }
        ]
      },
      dialogForBio: {
        title: "Add a bio",
        data: [
          {
            model: null,
            placeholder: "Write Something About Yourself"
          }
        ]
      },
      dialogForTag1: {
        title: "Add 3 BeeZTags",
        data: [
          {
            model: null,
            placeholder: "Tag #1"
          },
          {
            model: null,
            placeholder: "Tag #2"
          },
          {
            model: null,
            placeholder: "Tag #3"
          }
        ]
      },
      dialogForTag2: {
        title: "Add 3 BeeZTags",
        data: [
          {
            model: null,
            placeholder: "Tag #1"
          },
          {
            model: null,
            placeholder: "Tag #2"
          },
          {
            model: null,
            placeholder: "Tag #3"
          }
        ]
      },
      avatarTrigger: "#avatar",
      avatarCropper: {
        labels: {
          submit: "Done",
          cancel: "Cancel"
        },
        options: {
          aspectRatio: 1
        },
        mime: "image/jpeg"
      }
    };
  },
  validations: {
    selectedAvatarImage: {
      required
    }
  },
  components: {
    BzTags,
    BzDialog,
    TooltipComponent,
    BzNotification,
    AvatarCropper
  },
  async created() {
    if (this.userImage) {
      this.imageUrl = this.userImage.url;
    }

    eventBus.$on("openFilePicker", () => {
      this.onfileselected();
    });
    eventBus.$on("showJobTip", () => {
      this.jobTooltip = true;
    });
    eventBus.$on("showBioTip", () => {
      this.bioTooltip = true;
    });
    eventBus.$on("showExpertTip", () => {
      this.expertTooltip = true;
    });
    eventBus.$on("showProjectsTip", () => {
      this.projectsTooltip = true;
    });
    // this.tooltipMessages = this.setTooltipMessages();
  },
  methods: {
    ...mapActions("admin", {
      uploadFile: "uploadFile"
    }),
    ...mapActions("introduction", {
      syncStep: "syncStep"
    }),
    handleChange(file) {
      if (file.type.split("/")[0] != "image") {
        // this.notify("error", "An unexpected error has occured");
        this.correctFormat = false;
        this.showCropper = false;

        return;
      } else {
        this.image = file;
        this.correctFormat = true;
        this.showCropper = true;
      }
    },
    onfileselected() {
      this.$refs.onfile.click();
    },
    reselectPhoto() {
      this.onfileselected();
    },
    dialogSubmitted() {
      const dialogData = JSON.parse(JSON.stringify(this.dialogFor.data));
      const step = this.dialogStep;
      this.syncStep({ Stepper: step });
      this.$emit("dialogData", { dialogData, step });
      this.StepDispatch(step);
      eventBus.$emit("nextTip");
      this.showDialog = false;
    },
    uploadAvatar(file) {
      if (this.correctFormat) {
        file.getCroppedCanvas().toBlob(blob => {
          if (blob !== null) {
            this.avatarBlob = blob;
            this.uploadProfileImage();
          }
        });

        this.selectedAvatarImage = file
          .getCroppedCanvas()
          .toDataURL(this.avatarCropper.mime);
      } else {
        return;
      }
    },

    async uploadProfileImage() {
      if (this.image === null) {
        return;
      }
      this.uploadLoading = true;
      try {
        const uploaded = await this.uploadFile({
          fileData: this.avatarBlob
        });
        this.correctFormat = false;

        this.imageUrl = this.selectedAvatarImage;
        this.$emit("dialogData", { dialogData: uploaded[0]._id, step: 0 });
        eventBus.$emit("imageChangeEvent", this.imageUrl);
        if (this.completedSteps.length == 0) {
          eventBus.$emit("imageUploadedEvent", 0);
        }
        eventBus.$emit("imageUploadCompletedEvent");
      } catch (error) {
        this.correctFormat = false;
        // this.notify("error", error.message);
      } finally {
        this.uploadLoading = false;
      }
    },
    openDialog(step) {
      this.dialogStep = step;
      const completedRange = this.completedSteps.length - 1;
      let pass = null;

      switch (step) {
        case 1:
          pass = null;
          if (
            (pass = this.completedSteps.find(completes => completes === step))
          ) {
            if (pass !== null || !pass) {
              this.dialogForJobTitle.data[0].model = this.userCardData.position;
              this.dialogFor = this.dialogForJobTitle;
              this.showDialog = true;
            }
          } else if (this.completedSteps[completedRange] + 1 === step) {
            this.dialogForJobTitle.data[0].model = this.userCardData.position;
            this.dialogFor = this.dialogForJobTitle;
            this.showDialog = true;
          } else {
            this.showDialog = false;
            break;
          }
          break;
        case 2:
          pass = null;
          if (
            (pass = this.completedSteps.find(completes => completes === step))
          ) {
            if (pass !== null || !pass) {
              this.dialogForBio.data[0].model = this.userCardData.about;
              this.dialogFor = this.dialogForBio;
              this.showDialog = true;
            }
          } else if (this.completedSteps[completedRange] + 1 === step) {
            this.dialogForBio.data[0].model = this.userCardData.about;
            this.dialogFor = this.dialogForBio;
            this.showDialog = true;
          } else {
            this.showDialog = false;
            break;
          }
          break;
        case 3:
          pass = null;
          if (
            (pass = this.completedSteps.find(completes => completes === step))
          ) {
            if (pass !== null || !pass) {
              for (var index in this.userCardData.cardTags[0].tags) {
                this.dialogForTag1.data[
                  index
                ].model = this.userCardData.cardTags[0].tags[index];
              }
              this.dialogFor = this.dialogForTag1;
              this.showDialog = true;
            }
          } else if (this.completedSteps[completedRange] + 1 === step) {
            for (var k in this.userCardData.cardTags[0].tags) {
              this.dialogForTag1.data[
                k
              ].model = this.userCardData.cardTags[0].tags[k];
            }
            this.dialogFor = this.dialogForTag1;
            this.showDialog = true;
          } else {
            this.showDialog = false;
            break;
          }
          break;
        case 4:
          pass = null;
          if (
            (pass = this.completedSteps.find(completes => completes === step))
          ) {
            if (pass !== null || !pass) {
              for (var i in this.userCardData.cardTags[1].tags) {
                this.dialogForTag2.data[
                  i
                ].model = this.userCardData.cardTags[1].tags[i];
              }
              this.dialogFor = this.dialogForTag2;
              this.showDialog = true;
            }
          } else if (this.completedSteps[completedRange] + 1 === step) {
            for (var j in this.userCardData.cardTags[1].tags) {
              this.dialogForTag2.data[
                j
              ].model = this.userCardData.cardTags[1].tags[j];
            }
            this.dialogFor = this.dialogForTag2;
            this.showDialog = true;
          } else {
            this.showDialog = false;
            break;
          }
          break;
      }
    },
    StepDispatch(stepper) {
      switch (stepper) {
        case 1:
          eventBus.$emit("positionCompletedEvent");
          break;
        case 2:
          eventBus.$emit("bioCompletedEvent");
          break;
        case 3:
          eventBus.$emit("expertiseCompletedEvent");
          break;
        case 4:
          eventBus.$emit("projectsCompletedEvent");
          break;
      }
      this.showDialog = true;
    }
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("introduction", ["completedSteps"])
  },
  props: {
    section0: {
      type: String,
      required: false
    },
    section1: {
      type: String,
      required: false
    },
    jobTitle: {
      type: String,
      required: false
    },
    aboutTitle: {
      type: String,
      required: false,
      default: ""
    },
    userCardData: {
      type: Object,
      required: false,
      default: {}
    },
    userImage: {
      required: false,
      default: null
    }
  }
};
</script>

<style scoped>
.bg-image-container {
  background: #4e4e4e url("../../../../../assets/dark_backdrop_user.svg") 0% 0%
    padding-box;
  border-radius: 3px;
  opacity: 1;
}

.intro-card {
  box-shadow: 0px 0px 99px #00000029;
}

.camera-icon-container {
  background: #a1a1a1 no-repeat center center padding-box;
  box-shadow: 0px 18px 33px #0000000d;
  border-radius: 6px;
  opacity: 1;
}
.image-part {
  box-shadow: 0px 18px 33px #0000000d;
  border-radius: 6px;
  opacity: 1;
}
/* .text-container {
  word-break: keep-all;
} */
</style>