<template>
  <v-dialog
    v-model="display"
    persistent
    content-class="greyishBackground congratulations"
    max-width="1000"
  >
    <div class="mx-auto mb-5 d-flex px-10 py-12 align-center congratulations-top">
      <h1 class="display-2 mr-5 font-weight-light">CONGRATULATIONS</h1>
      <div class="headlineTitle pl-3">
        <span v-if="type == 'onboarding'">You have successfully Finished the On boarding Process.</span>
        <span
          v-else-if="type == 'introduction'"
        >You have successfully completed building your BeeZ Card.</span>
        <span v-else-if="type == 'course'">You have Created a new Course</span>
      </div>
    </div>
    <div class="d-flex white justify-space-between congratulations-bottom">
      <div class="d-flex">
        <v-img
          src="@/assets/thumbs_up.png"
          contain
          class="congratulations-bottom-image ml-5 mr-10"
          height="200"
          width="200"
        ></v-img>
        <div class="ml-10 primary--text headline mt-5" v-if="type == 'course'">{{ beezInCode }}</div>
      </div>

      <div class="mt-5 mr-2 congratulations-bottom-body">
        <div class="d-flex justify-space-between">
          <v-btn
            color="primary"
            elevation="0"
            class="px-10"
            large
            @click="preview"
            v-if="type == 'introduction'"
          >Preview Card</v-btn>
          <v-btn
            v-if="type == 'onboarding' || type == 'introduction'"
            color="textOrange textLight--text"
            elevation="0"
            class="px-10"
            large
            @click="goto"
          >Continue</v-btn>
          <v-btn
            color="primary"
            elevation="0"
            class="px-10"
            large
            @click="shareBeezIn"
            v-if="type == 'course'"
          >Share BeeZ-In</v-btn>

          <v-btn v-if="type == 'course'" elevation="0" class="px-10" large @click="goto">Skip</v-btn>
        </div>
        <div class="body-2 px-1 mt-3" v-if="type == 'introduction' || type == 'onboarding'">
          If you would like to make changes to your Intro BeeZCard or review The
          Welcome card, you can do so from the Onboarding Home Screen.
        </div>
        <div class="body-2 px-1 mt-3" v-if="type == 'course'">
          Your Training is now Active, You can now share this BeeZ-In code to
          your trainees, so they could take the Training
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: ["display", "continueTo", "type", "beezInCode"],
  methods: {
    shareBeezIn() {
      this.$emit("shareBeezIn");
    },
    preview() {
      this.$emit("preview");
    },
    goto() {
      if (this.continueTo.name) {
        this.$router.replace({
          name: this.continueTo.name,
          query: {
            course: this.continueTo.course,
            id: this.continueTo.id
          }
        });
      } else {
        this.$router.replace({ name: this.continueTo });
      }
    }
  }
};
</script>

<style>
.headlineTitle {
  border-left: 2px solid white;
  font-weight: 100;
  font-size: larger;
}
.greyishBackground {
  background: #119fc1;
  opacity: 1;
}
.congratulations {
  height: 500px;
}
.congratulations-top {
  background-color: #119fc1;
  color: white;
  height: 60%;
  width: 90%;
}
.congratulations-bottom {
  height: 36%;
}
.congratulations-bottom-image {
  top: -90px;
}
.congratulations-bottom-body {
  width: 40%;
}
</style>
