<template>
  <v-row>
    <!-- For Image currentStepper  -->
    <v-tooltip
      v-if="isImage"
      v-model="imageTooltip"
      right
      color="primary"
      content-class="tooltipClass"
    >
      <template v-slot:activator="{ on }" v-on="$listeners" v-bind="$attrs">
        <v-icon
          @click="openFilePickerEvent"
          size="60"
          class="pt-6 pb-6 pl-10"
          color="textDark"
          v-on="on"
        >mdi-camera</v-icon>
      </template>
      <span class="textLight--text body-1 font-weight-light">{{tooltipMessages[step].text}}</span>
      <span
        class="textDark--text caption font-weight-regular pt-2 pl-4"
      >{{tooltipMessages[step].tip}}</span>
    </v-tooltip>

    <!-- For Buttons Tooltip -->

    <v-tooltip
      :right="side"
      :left="!side"
      v-if="isButton"
      :value="jobTip || bioTip || expertTip || projectsTip"
      color="primary"
      content-class="tooltipClass"
    >
      <template v-slot:activator="{ on }" v-on="$listeners" v-bind="$attrs">
        <slot name="tooltip"></slot>
        <v-icon
          class="my-0 pa-0 mr-0 ml-3"
          v-if="step===1?iconColor='textLight':iconColor='textDark'"
          size="25"
          v-on="on"
          :color="iconColor"
        >mdi-pencil</v-icon>
      </template>
      <v-row justify="start" class="pl-1 ma-0 py-0">
        <span class="textLight--text body-1 font-weight-light">{{tooltipMessages[step].text}}</span>
        <span
          class="textDark--text caption font-weight-regular pt-1 pl-2"
        >{{tooltipMessages[step].tip}}</span>
      </v-row>
    </v-tooltip>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { eventBus } from "@/main";
export default {
  name: "TooltipComponent",
  data() {
    return {
      iconColor: "",
      SyncMotion: 0,
      imageTooltip: true,
      bioTooltip: true
    };
  },
  props: {
    tooltipMessages: {
      type: Array,
      required: true
    },
    step: {
      type: Number,
      required: false
    },
    side: {
      type: Boolean,
      required: false
    },
    isImage: {
      type: Boolean,
      required: false
    },
    jobTip: {
      type: Boolean,
      required: false
    },
    bioTip: {
      type: Boolean,
      required: false
    },
    expertTip: {
      type: Boolean,
      required: false
    },
    projectsTip: {
      type: Boolean,
      required: false
    },
    isButton: {
      type: Boolean,
      required: false
    },
    isTextField: {
      type: Boolean,
      required: false
    }
  },
  async created() {
    eventBus.$on("imageUploadedEvent", step => {
      this.syncStep({ Stepper: step });
      this.showTooltip();
    });
    eventBus.$on("nextTip", () => {
      this.showTooltip();
    });
  },
  computed: {
    ...mapFields("introduction", ["currentStepper", "completedSteps"])
  },
  methods: {
    ...mapActions("introduction", {
      syncStep: "syncStep"
    }),
    openFilePickerEvent() {
      eventBus.$emit("openFilePicker");
    },
    showTooltip() {
      if (this.currentStepper !== 0) {
        this.imageTooltip = false;
      }
      if (this.currentStepper === 0) {
        eventBus.$emit("showJobTip");
      }
      if (this.currentStepper === 1) {
        eventBus.$emit("showBioTip");
      }
      if (this.currentStepper === 2) {
        eventBus.$emit("showExpertTip");
      }
      if (this.currentStepper === 3) {
        eventBus.$emit("showProjectsTip");
      }
    }
  }
};
</script> 

<style scoped>
.tooltipClass {
  width: 285px;
  margin-top: -20px;
  margin-left: 20px;
  border-radius: 16px 16px 16px 16px;
}
.tooltipClasser {
  visibility: hidden;
}
</style>